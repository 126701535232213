.form-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #27496d;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* margin-top: 60px; */
}

.form-container h1 {
  text-align: center;
  color: #c1dadf;
}
.custom-select .ant-select-selection-placeholder {
  color: black !important; /* Change this to your desired placeholder color */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #c1dadf;
  text-align: left;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.radio-group {
  display: flex;
  align-items: center;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
}

.radio-group label {
  margin-bottom: 0; /* Ensure labels do not break lines */
}

:where(.css-dev-only-do-not-override-3rel02).ant-form-item
  .ant-form-item-label
  > label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #c1dadf;
  text-align: left;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

.form-group.checkbox-group {
  display: flex;
  align-items: center;
}

.form-group.checkbox-group label {
  margin-left: 8px; /* Space between checkbox and label text */
}

.custom-radio {
  transform: scale(1.5); /* Adjust the scale value as needed */
  margin-right: 5px; /* Optional: add some space between the radio button and the label */
}

/* Optional: Adjust the size and position of the labels */
.radio-group label {
  font-size: 1.2em; /* Increase the font size */
  margin-left: 1px; /* Add some space between the radio button and the label */
}

.success-message-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #52c41a;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.success-message {
  font-size: 24px;
  line-height: 1.5;
}

@media (max-width: 600px) {
  .form-container {
    padding: 20px; /* Reduce padding for smaller screens */
    margin-top: -10px; /* Reduce margin for smaller screens */
    border-radius: 0; /* Remove border radius for a cleaner look */
    box-shadow: none; /* Remove box shadow for a cleaner look */
  }

  .form-group label {
    font-size: 14px; /* Reduce font size for labels on smaller screens */
  }

  .radio-group label {
    margin-right: 10px; /* Reduce margin between radio groups for smaller screens */
  }

  button {
    font-size: 14px; /* Reduce font size for button on smaller screens */
  }
}
