.testimonial-container {
  margin: 0 auto;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
}

.testimonial-container h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 60px;
  color: #2c3e50;
}

.testimonial-rating .star {
  color: yellow;
}

/* Style for the carousel */
.carousel-container {
  margin-top: 20px;
}

/* Style for individual testimonial items */
.testimonial {
  padding: 20px;
  margin: 0 10px;
  background-color: #c1dadf;
  border-radius: 10px;
  transition: transform 0.5s ease; /* Smooth transition */
}

.testimonial p {
  font-size: 18px;
  line-height: 1.6;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo-container img {
  max-width: 200px;
  height: auto;
}

.testimonial-author {
  margin-top: 15px;
}

.testimonial-author .name {
  font-weight: bold;
}

.testimonial-author .position {
  color: #777;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .testimonial-container {
    margin-top: -30px;
    margin-bottom: 30px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .testimonial-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .testimonial {
    padding: 10px;
    margin: 0 3px;
    height: 18pc;
  }

  .testimonial p {
    font-size: 14px;
    line-height: 1.3;
  }

  .testimonial-author {
    margin-top: 5px;
  }

  .logo-container {
    text-align: center;
    margin-bottom: 0px;
  }

  .logo-container img {
    max-width: 100px;
    height: auto;
  }
}
