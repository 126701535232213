.about-us {
  max-width: 800px;
  margin: auto;
  color: #333;
  font-family: "Arial", sans-serif;
}

.about-us h1 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 10px;
  color: #2c3e50;
}

.about-us h2 {
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #2980b9;
}

.about-us p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
}

@media (max-width: 600px) {
  .about-us {
    padding: 20px;
    margin-top: -60px;
  }
  .about-us h1 {
    font-size: 24px; /* Further adjust font size for smaller screens */
    margin-bottom: 8px; /* Further adjust margin for smaller screens */
  }

  .about-us h2 {
    font-size: 20px; /* Further adjust font size for smaller screens */
    margin-bottom: 16px; /* Further adjust margin for smaller screens */
  }

  .about-us p {
    font-size: 14px; /* Further adjust font size for smaller screens */
  }
}
