/* .cibil-button {
  display: inline-block;
  padding: 20px 20px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: #142850;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  animation: blink 1.5s infinite;
  transition: background-color 0.3s ease;
  max-width: 300px;
} */

/* Parent container */
.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:40px ;
}

/* Button styling */
.cibil-button {
  display: inline-block;
  padding: 10px 10px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  background-color: #142850;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  animation: blink 1.5s infinite;
  transition: background-color 0.3s ease;
  max-width: 300px;
}

.cibil-button:hover {
  background-color: #142850;
}

@keyframes blink {
  0% {
    background-color: #142850;
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: orange;
  }
}

.whatsapp-float {
  position: fixed;
  width: 55px; /* Adjust as needed */
  height: 55px; /* Adjust as needed */
  bottom: 40px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: background-color 0.3s, transform 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
}

.whatsapp-float:hover {
  background-color: #128c7e;
  transform: scale(1.1);
}

.whatsapp-icon {
  font-size: 38px; /* Adjust as needed */
}

@media (max-width: 600px) {
  .cibil-button {
    padding: 10px 15px; /* Further adjust padding for smaller screens */
    font-size: 16px; /* Further adjust font size for smaller screens */
  }
}
