body {
  font-family: "Arial", sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.emi-container {
  max-width: 600px;
  margin: 60px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.emi-title {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.emi-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group label {
  font-size: 16px;

  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #007bff;
}

.calculate-button {
  padding: 15px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #142850;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calculate-button:hover {
  background-color: #0056b3;
}

.emi-result {
  margin-top: 20px;
}

.emi-result h2 {
  font-size: 24px;
  font-weight: 700;
  color: #333;
}

.emi-result span {
  color: #007bff;
}

@media (max-width: 600px) {
  .emi-container {
    padding: 20px; /* Adjust padding for smaller screens */
    border-radius: 0px;
    margin-top: 50px;
  }

  .emi-title {
    font-size: 24px; /* Further adjust font size for smaller screens */
    margin-bottom: 10px; /* Further adjust margin for smaller screens */
  }

  .form-group input,
  .calculate-button {
    padding: 12px 16px; /* Further adjust padding for smaller screens */
    font-size: 14px; /* Further adjust font size for smaller screens */
  }

  .emi-result h2 {
    font-size: 20px; /* Further adjust font size for smaller screens */
  }
}
