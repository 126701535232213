/* .App {
  text-align: center;
  background-color: white;
} */
.custom-select {
  position: relative;
  display: inline-block;
  width: 200px;
}

.select-selected {
  background-color: #e5e5e5;
  padding: 10px 12px;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.select-selected:after {
  content: "";
  position: absolute;
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #000 transparent transparent transparent;
}

.select-items {
  position: absolute;
  background-color: #f9f9f9;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.select-items div {
  color: #000;
  padding: 10px 12px;
  cursor: pointer;
  user-select: none;
}

.select-items div:hover {
  background-color: #e5e5e5;
}
