.offer-section {
  max-width: 1200px;
  margin: 60px auto;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.offer-heading {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
  letter-spacing: 1px;
  animation: blink-animation 1s steps(2, start) infinite,
    color-change 5s infinite;
}

@keyframes color-change {
  0% {
    color: #333;
  }
  25% {
    color: #ff0000; /* Red */
  }
  50% {
    color: #00ff00; /* Green */
  }
  75% {
    color: #0000ff; /* Blue */
  }
  100% {
    color: #333;
  }
}

@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.offer-container {
  background-color: #c1dadf;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-top: 20px;
}

.offer-text {
  flex: 1;
  padding: 20px;
}

.offer-title {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.offer-description {
  font-size: 18px;
  color: #666;
  line-height: 1.6;
}

.offer-image {
  flex: 1;
  text-align: center;
}

.offer-image img {
  max-width: 100%;
  border-radius: 8px;
  height: 250px;
}

@media (max-width: 600px) {
  .offer-container {
    padding: 10px; /* Reduce padding for smaller screens */
    margin-top: 40px;
    border-radius: 0px;
    flex-direction: column;
  }

  .offer-title {
    font-size: 20px; /* Further reduce font size for smaller screens */
  }

  .offer-description {
    font-size: 14px; /* Further reduce font size for smaller screens */
  }

  .offer-image img {
    height: 200px;
    width: 230px;
  }
}
