.header {
  position: fixed; /* Make the header fixed */
  top: 0; /* Position it at the top of the viewport */
  left: 0; /* Position it at the left edge of the viewport */
  width: 100%; /* Make it span the full width of the viewport */
  z-index: 1000; /* Ensure it's above other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #0c7b93;
  color: white;
}

.logo img {
  width: 120px;
  height: 40px;
  margin-top: 5px;
}

.menu-icon {
  display: none;
  font-size: 25px;
  cursor: pointer;
  color: #c1dadf; /* Ensure the icon is visible */
  margin-left: auto; /* Push the icon to the right */
  margin-right: 30px;
}

.nav {
  display: flex;
}

.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10px;
  padding-right: 95px;
}

.nav-menu li {
  margin-left: 30px;
}

.nav-menu a {
  color: #c1dadf;
  text-decoration: none;
  font-size: 18px;
  font-weight: 550;
}

.nav-menu a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: center;
    padding: 1px 20px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 14px;
    right: 25px;
  }

  .nav {
    display: none;
    width: 100%;
    flex-direction: column;
  }

  .nav-menu {
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .nav-menu li {
    margin: 10px 0;
    text-align: left;
  }

  .nav.open {
    display: flex;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #0c7b93;
    width: 100%;
  }

  .logo img {
    width: 100px;
    height: 50px;
  }
}
