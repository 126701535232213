/* heroSection.css */
.elementor-9162
  .elementor-element.elementor-element-ce8a110
  .elementor-background-slideshow__slide__image {
  background-position: center center;
}

.elementor .elementor-background-slideshow__slide__image {
  width: 100%;
  background-size: cover;
}

.elementor *,
.elementor :after,
.elementor :before {
  box-sizing: border-box;
}

.hero-carousel img {
  height: 400px;
  width: 100%;
  margin-top: 55px;
}

@media only screen and (max-width: 768px) {
  .elementor-9162
    .elementor-element.elementor-element-ce8a110
    .elementor-background-slideshow__slide__image {
    background-position: center center !important; /* Override existing background position */
  }

  .elementor .elementor-background-slideshow__slide__image {
    background-size: cover !important; /* Ensure background covers the entire area */
  }

  .hero-carousel img {
    height: 150px !important; /* Reset height to auto for responsiveness */
    width: 100% !important; /* Ensure image width spans the container */
    margin-top: 60px;
  }
}
