.footer {
  background-color: #0c7b93;
  color: #fff;
  padding: 50px 0;
}

.footer p {
  color: #c1dadf;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-row {
  display: flex;
  justify-content: space-between;
}

.footer-column {
  width: 25%;
  text-align: center;
}

.footer-column h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 20px;
}
.footer-column a {
  color: #c1dadf;
}

.footer-section.quick-links ul {
  list-style: none; /* Remove bullet points */
}

.footer-section.quick-links a {
  color: white; /* Set text color to white */
  text-decoration: none; /* Remove underline */
}

.footer-column form input {
  width: 91.5%;
  padding: 10px;
  margin-bottom: 10px;
}

.footer-column form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #333;
  border: none;
  cursor: pointer;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.social-icons a {
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
}

.social-icons a:last-child {
  margin-right: 0;
}

.footer-bottom {
  text-align: center;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #c1dadf;
}

.logo-container2 img {
  height: 100px;
  width: 200px;
}

@media (max-width: 767px) {
  .footer-row {
    flex-wrap: wrap;
  }

  .footer-column {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .footer-column {
    width: 100%;
  }
  .logo-container2 img {
    height: 100px;
    width: 200px;
  }
}
